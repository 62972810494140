<template>
  <div id="cashCoupon">
    <div v-if="$route.path == '/voucher'">
      <div class="top">
        <p>
          代金券可以设置价格出售，购买后可以抵扣对应金额的凭证；叮叮开课提供代金券的出售和核销功能，您可以在任意平台交易中使用。
        </p>
        <p>
          使用流程：创建代金券->直播间出售->学员购买代金券->学员报名课程->出示代金券->核销
        </p>
        <p>
          温馨提示：目前代金券仅在公开课直播间推送出售，创建好后可在控制台推送模块操作
        </p>
      </div>
      <div class="main">
        <div class="header">
          <el-button type="primary" @click="add">新建代金券</el-button>
          <div class="flex-center">
            <el-select
              v-model="post.price_type"
              style="width: 120px"
              size="medium"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              size="medium"
              v-model="post.name"
              class="ml20"
              style="width: 218px"
              placeholder="输入名称搜索"
            ></el-input>
          </div>
        </div>
        <pagination2
          :option="post"
          url="/LiveGoods/voucherList"
          ref="childDialogBox"
          class="childDialogBox"
        >
          <template v-slot:default="{ tableData }">
            <el-table
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="tableData"
            >
              <el-table-column label="代金券名称" min-width="150px">
                <template slot-scope="{ row }">
                  <div class="good_contain">
                    <div class="pic">
                      <img :src="row.cover_img" alt="" />
                    </div>
                    <div class="name_price">
                      <div class="name">{{ row.name }}</div>
                      <div class="price">
                        <span v-if="row.price">￥{{ row.price }}</span>
                        <span v-else>￥0</span>
                        <!-- <span class="corss_price" v-if="row.cost_price">
                        ￥{{ row.cost_price }}
                      </span> -->
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="已售(张)">
                <template slot-scope="{ row }">
                  <span v-if="row.price_type == 1">{{ row.sale_num }}</span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="支付方式">
                <template slot-scope="{ row }">
                  {{ row.price_type | gettype }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="状态">
              <template slot-scope="{ row }">
                <span v-if="row.state == 1" style="color: #3d80ef">已启用</span>
                <span v-else style="color: #ff3535">已停用</span>
              </template>
            </el-table-column> -->
              <el-table-column label="操作" width="180px">
                <template slot-scope="{ row }">
                  <div class="btns">
                    <el-button
                      :disabled="row.price_type == 2"
                      type="text"
                      @click="
                        $router.push({
                          path: '/voucher/purchaserecord',
                          query: { id: row.live_goods_id },
                        })
                      "
                    >
                      购买记录
                    </el-button>
                    <!-- <span class="sp_dir">|</span>
                  <el-button
                    v-if="row.state == 1"
                    type="text"
                    @click="openStop(row.live_goods_id, 2)"
                  >
                    停用
                  </el-button>
                  <el-button
                    v-else
                    type="text"
                    @click="openStop(row.live_goods_id, 1)"
                  >
                    启用
                  </el-button> -->
                    <span class="sp_dir">|</span>
                    <el-dropdown>
                      <el-button type="text">更多</el-button>
                      <el-dropdown-menu slot="dropdown">
                        <p title="编辑" @click="edit(row.live_goods_id)">
                          <el-dropdown-item>编辑</el-dropdown-item>
                        </p>

                        <p title="删除" @click="dels(row.live_goods_id)">
                          <el-dropdown-item>删除</el-dropdown-item>
                        </p>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <!-- <template #footer>
          <div class="footer flex-center">
            已选择{{ selectionVal.length }} 条
            <el-button
              :disabled="selectionVal.length == 0"
              size="medium"
              class="ml20"
              @click="dels(undefined)"
              style="width: 130px"
            >
              批量删除
            </el-button>
          </div>
        </template> -->
        </pagination2>
        <!-- 添加编辑商品 -->
        <el-dialog
          :visible.sync="dialogVisible"
          class="dialogVisible"
          width="800px"
          @closed="close"
          :close-on-click-modal="false"
          :destroy-on-close="true"
        >
          <div slot="title" class="title">
            {{ isaddOrEdit == 1 ? '新建代金券' : '编辑代金券' }}
            <!-- <span class="tip">
            提示：目前仅支持添加外部商品，添加后可在直播间推送
          </span> -->
          </div>
          <div class="dialog_contain">
            <el-form
              :model="ruleForm"
              ref="ruleForm"
              label-width="92px"
              class="demo-ruleForm"
              label-position="left"
            >
              <el-form-item label="封面" :required="true">
                <div class="tu_contian">
                  <div class="left">
                    <img
                      v-if="ruleForm.cover_img"
                      :src="ruleForm.cover_img"
                      alt=""
                    />
                  </div>
                  <div class="select_pic">
                    <div class="size_tip">
                      建议尺寸600*600px以上正方形图片，2M以内，JPG、PNG格式
                    </div>

                    <el-button size="medium" style="width: 120px">
                      选择图片
                    </el-button>
                    <change-photoimg
                      :size="2"
                      @complete="completephoto"
                      class="changePhotoimg"
                    ></change-photoimg>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="代金券名称" :required="true">
                <el-input
                  style="width: 617px"
                  v-model="ruleForm.name"
                  placeholder="请输入代金券名称"
                  maxlength="16"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="售价" :required="true">
                <div class="price_contain">
                  <span class="sp_1">
                    <el-input
                      style="width: 180px"
                      :value="ruleForm.price"
                      @input="ruleForm.price = toNumber(arguments[0])"
                      class="mr10"
                      maxlength="10"
                      placeholder="请输入金额"
                    ></el-input>
                    元
                  </span>
                  <span class="sp_2">
                    <span class="sp_3">，代</span>
                    <el-input
                      maxlength="10"
                      style="width: 180px"
                      :value="ruleForm.cost_price"
                      @input="ruleForm.cost_price = toNumber(arguments[0])"
                      class="mr10"
                      placeholder="请输入金额"
                    ></el-input>
                    元（比如90元代100元）
                  </span>
                </div>
              </el-form-item>
              <el-form-item label="支付方式" class="ml10">
                <div slot="label">
                  支付方式
                  <helpIcon
                    style="margin-left: 6px"
                    content="直播间支付可以在网校查看订单信息，跳转支付则输入链接地址跳转第三方平台"
                  ></helpIcon>
                </div>
                <el-radio-group v-model="ruleForm.price_type">
                  <el-radio label="1" :disabled="isaddOrEdit == 2">直播间支付</el-radio>
                  <el-radio label="2" :disabled="isaddOrEdit == 2">跳转支付</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="跳转链接"
                :required="true"
                v-if="ruleForm.price_type == 2"
              >
                <el-input
                  style="width: 617px"
                  v-model="ruleForm.url"
                  placeholder="请输入https开头的链接"
                  maxlength="500"
                ></el-input>
              </el-form-item>
              <div class="price_tips" v-if="ruleForm.price_type == 2">
                提示：建议填写与代金券相关的介绍链接、活动链接、商品链接，在直播间推送后点击代金券会跳转该链接
              </div>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" size="medium">
              取 消
            </el-button>
            <el-button type="primary" @click="submit" size="medium">
              保存
            </el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import bg from '@/mixin/background'
export default {
  name: 'cashCoupon',
  mixins: [bg],
  data() {
    return {
      radio: '1',

      /* 一下备用 **/
      selectionVal: [],
      post: {
        price_type: '0',
        name: '',
      },

      // 1,添加 2，编辑
      isaddOrEdit: 1,

      ruleForm: {
        name: '',
        cover_img: '',
        url: '',
        cost_price: '',
        price: '',
        price_type: '1',
      },

      options: [
        {
          value: '0',
          label: '全部方式',
        },
        {
          value: '1',
          label: '直播间支付',
        },
        {
          value: '2',
          label: '跳转支付',
        },
      ],

      dialogVisible: false,
    }
  },

  components: {
    changePhotoimg,
  },

  filters: {
    gettype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播间支付'
          break
        case 2:
          text = '跳转支付'
          break
      }
      return text
    },
  },

  methods: {
    openStop(id, state) {
      if (state == 2) {
        this.$confirm('是否确定停用代金券？', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(() => {
            this.stopVoucher(id, state)
          })
          .catch(() => {})
      } else {
        this.stopVoucher(id, state)
      }
    },

    stopVoucher(id, state) {
      this.$http({
        url: `/LiveGoods/stopVoucher`,
        data: {
          live_goods_id: id,
          state: state,
        },
        callback: () => {
          this.$refs.childDialogBox.reset()
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
        },
      })
    },

    dels(id) {
      this.$confirm('是否确定删除代金券？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.$http({
            url: '/LiveGoods/delGoods',
            data: {
              live_goods_id: [id],
            },
            callback: () => {
              this.$root.prompt({
                msg: '操作成功',
                type: 'success',
              })
              this.$refs.childDialogBox.reset()
            },
          })
        })
        .catch(() => {})
    },

    close() {
      this.ruleForm = {
        name: '',
        cover_img: '',
        url: '',
        cost_price: '',
        price: '',
        price_type: '1',
      }
    },

    edit(id) {
      this.dialogVisible = true
      this.isaddOrEdit = 2
      this.selectid = id
      this.$http({
        url: `/LiveGoods/setVoucher?live_goods_id=${id}`,
        callback: ({ data }) => {
          this.ruleForm = data
        },
      })
    },

    submit() {
      this.ruleForm = _.assign({}, this.ruleForm, {
        url: this.ruleForm.url.trim(),
        name: this.ruleForm.name.trim(),
      })

      if (!this.ruleForm.cover_img) {
        this.$root.prompt('请选择封面')
        return
      }
      if (!this.ruleForm.name.trim()) {
        this.$root.prompt('请输入代金券名称')
        return
      }

      if (!this.ruleForm.price || !this.ruleForm.cost_price) {
        this.$root.prompt('请输入金额')
        return
      }

      if (this.ruleForm.price_type == 2 && !this.ruleForm.url) {
        this.$root.prompt('请输入跳转链接')
        return
      }

      const reg = this.ruleForm.url.slice(0, 4)
      if (this.ruleForm.price_type == 2 && reg != 'http') {
        this.$root.prompt('链接格式有误！')
        return
      }

      if (this.isaddOrEdit == 1) {
        this.$http({
          url: '/LiveGoods/addVoucher',
          data: this.ruleForm,
          callback: () => {
            this.dialogVisible = false
            this.$refs.childDialogBox.reset()
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
          },
        })
      } else {
        this.$http({
          url: `/LiveGoods/setVoucher`,
          data: _.assign({}, this.ruleForm, { live_goods_id: this.selectid }),
          callback: () => {
            this.dialogVisible = false
            this.$refs.childDialogBox.reset()
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
          },
        })
      }
    },

    // Add picture
    completephoto(val) {
      this.ruleForm.cover_img = val
    },

    // Add item
    add() {
      this.isaddOrEdit = 1
      this.dialogVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
#cashCoupon {
  margin: 20px;
  .top {
    padding: 20px;
    background-color: #fff;
    margin-bottom: 20px;
    font-size: 13px;
    color: #333333;
    line-height: 20px;
  }
  .main {
    padding: 20px;
    background: #fff;
  }
  ::v-deep .el-form-item {
    display: flex !important;
    align-items: center !important;
    .el-form-item__content {
      margin-left: 40px !important;
    }
  }
  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .childDialogBox {
    .good_contain {
      display: flex;

      .pic {
        width: 60px;
        height: 60px;
        min-width: 60px;
        margin-right: 10px;
        img {
          object-position: 50%;
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      .name_price {
        flex: 1;
        overflow: hidden;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          color: #333333;
        }
        .price {
          margin-top: 20px;
          font-size: 14px;
          color: #ff3535;
          .corss_price {
            margin-left: 10px;
            text-decoration: line-through;
            color: #999999;
          }
        }
      }
    }
    .btns {
      display: flex;
      align-items: center;
      .sp_dir {
        color: #1b9d97;
        margin: 0 10px;
      }
    }
    .footer {
      margin-top: 20px;
      font-size: 14px;
      color: #333333;
    }
  }
  ::v-deep .dialogVisible {
    .el-dialog__body {
      padding: 20px;
    }
    .el-button {
      width: 100px;
    }
    .title {
      font-size: 14px;
      color: #333333;
      .tip {
        font-size: 12px;
        color: #ff3535;
        margin-left: 16px;
      }
    }

    .dialog_contain {
      .price_tips {
        font-size: 12px;
        color: #ff3535;
        margin-left: 128px;
      }
      .tu_contian {
        display: flex;
        .left {
          width: 80px;
          height: 80px;
          background: #e3e3e3;
          margin-right: 20px;
          img {
            object-position: 50%;
            object-fit: contain;
            height: 100%;
            width: 100%;
          }
        }
        .select_pic {
          position: relative;
          .size_tip {
            line-height: 13px;
            font-size: 13px;
            color: #333333;
            margin-bottom: 27px;
          }
          #changePhotoimg {
            bottom: 1px;
            position: absolute;
            width: 120px;
            height: 36px;
          }
        }
      }
      .price_contain {
        display: flex;
        .sp_1,
        .sp_2 {
          display: flex;
          align-items: center;
        }
        .sp_2 {
          .sp_3 {
            width: 38px;
          }
        }
      }
    }
  }
}
</style>